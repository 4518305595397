import React, { useMemo } from 'react';
import { BaseProps } from '../@types/common';
import Collapse from './Collapse';
import { characterIdType, characterType, charactersType, characterTypeType } from './ScreenBot';
import { useTranslation } from 'react-i18next';

type Props = BaseProps & {
  characters: charactersType;
  onSelectCharacter: (v: characterIdType) => void;
};

const ScreenBot: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  // データ構造を変形
  const characterTypes: Map<characterTypeType, characterType[]> = useMemo(() => {
    const result = new Map<characterTypeType, characterType[]>();
    for (const [, character] of props.characters) {
      if (!result.has(character.type)) {
        result.set(character.type, []);
      }
      result.get(character.type)?.push(character)
    }
    return result;
  }, [props.characters])

  return (
    <div className='mx-auto w-1/2' >
      <label>{t("screenBot.selectCharacter")}</label>
      {
        Array.from(characterTypes).map(([type, characters]) => {
          return (<Collapse
            key={type}
            className=''
            title={t(`screenBot.characterTypeNameFor.${type}`)}>
            <div className='m-2'>
              <div className='grid grid-cols-4 gap-4'>
                {characters.map((character) => {
                  return <div key={character.id} onClick={() => { props.onSelectCharacter(character.id) }}>
                    <img src={character.default}></img>
                    <label>{character.displayName}</label>
                  </div>
                })}
              </div>
            </div>
          </Collapse>
          )
        })
      }
    </div >
  );
};

export default ScreenBot;
